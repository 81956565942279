import axios from "axios";
import { canonicalizeResponse, authHeader } from "../common";

const getVendor = (vendor_id) => {
  return axios
    .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/vendor/" +  vendor_id + "/", {
      headers: authHeader(),
    })
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const listVendors = (body) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/vendors",
      body,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const saveVendor = (vendorData) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/vendor",
      vendorData,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};


export default {
  getVendor,
  listVendors,
  saveVendor
};
