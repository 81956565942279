import { useEffect } from "react";
import { useHistory } from "react-router-dom";


const SignOut = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.replace(process.env.REACT_APP_BASE_URL);
  }, []);

  return (
    <>
    <h1>Signing Out ... </h1>
    </>
  );
};

export default SignOut;

