import React, {  } from "react";
import { Switch, Route } from "react-router-dom";
import Client from "./pages/Client";

import SurveyCampaign from "./pages/SurveyCampaign";

import Home from "./pages/Home";
import Main from "./components/layout/Main";
import { getIpDetails } from "./services/identityService"
import "antd/dist/antd.min.css"
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";
import Vendor from "./pages/Vendor";
import SignOut from "./pages/SignOut"


function App() {
  console.log("Came at APP")
  getIpDetails().then((res)=>{})
  const { isAdmin } = useSelector((state) => state.auth);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          {isAdmin && 
            <Main>
              <Route exact path="/survey" component={SurveyCampaign} />
              <Route exact path="/client" component={Client} />
              <Route exact path="/vendor" component={Vendor} />
              <Route exact path="/signout" component={SignOut} />
            </Main>
          }
          
          {!isAdmin && <Route path="*" exact component={Home} />}

        </Switch> 
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
