import {
  ALL_CATEGORY_DATA_REQUESTED,
  ALL_CATEGORY_DATA_SUCCESS,
  ALL_CATEGORY_DATA_ERROR,
  CATEGORY_DATA_SUBMIT_INITIATED,
  CATEGORY_DATA_SUBMIT_SUCCESS,
  CATEGORY_DATA_SUBMIT_ERROR
} from "../actions/profileActions/actionTypes";

const INITIAL_STATE = {
  allCategoryData: {},
  fetchingCategoryData: false,
  categoryDataError: null,
  submitingCategoryData: false,
  submitingCategoryDataError: null,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALL_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        fetchingCategoryData: true,
        categoryDataError: null,
      };
    case ALL_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        allCategoryData: payload,
        fetchingCategoryData: false,
      };
    case ALL_CATEGORY_DATA_ERROR:
      return {
        ...state,
        fetchingCategoryData: false,
        categoryDataError: payload,
      };
    case CATEGORY_DATA_SUBMIT_INITIATED:
      return {
        ...state,
        submitingCategoryData: true,
        questionsDataError: null,
      };
    case CATEGORY_DATA_SUBMIT_SUCCESS:
      const allCategoryData = state.allCategoryData
      allCategoryData[payload.id] = payload
      return {
        ...state,
        allCategoryData: allCategoryData,
        submitingCategoryData: false,
      };
    case CATEGORY_DATA_SUBMIT_ERROR:
      return {
        ...state,
        submitingCategoryData: false,
        questionsDataError: payload,
      };
  
    default:
      return state;
  }
};

export default profileReducer;
