import { combineReducers } from "redux";
import authMessageReducer from "./authMessageReducer";
import authReducer from "./authReducer";
import clientReducer from "./clientReducer";
import profileReducer from "./profileReducer";
import rewardReducer from "./rewardReducer";
import surveyReducer from "./surveyReducer";
import vendorReducer from "./vendorReducer";

export default combineReducers({
  profile: profileReducer,
  auth: authReducer,
  message: authMessageReducer,
  reward: rewardReducer,
  client: clientReducer,
  vendor: vendorReducer,
  survey: surveyReducer
});
