import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,

} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveVendorData } from "../../redux/actions/vendorActions";


const { Option } = Select;
const EditVendorDrawer = ({
  isEditableDrawerOpen,
  setIsEditableDrawerOpen,
  particularVendorInformation,
  setParticularVendorInformation,
  reloadVendors,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { filterData = {} } = useSelector(({ survey }) => survey);

  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    reloadVendors();
  }

  const onFinish = (value) => {
    console.log(value);
    value.other = {}
    value.other_redirects = {}
    value.is_vendor_manual = false
    dispatch(saveVendorData(value, onSaveSuccess));
  };

  useEffect(() => {
    if (Object.keys(particularVendorInformation).length) {
      form.setFieldsValue(particularVendorInformation);
    }
  }, [particularVendorInformation, form]);



  return (
    <>
      <Drawer
        title="Vendor Details"
        width={720}
        closable={false}
        onClose={onClose}
        open={true}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button form="vendor_submit_form" key="submit" htmlType="submit" type="primary" >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          id="vendor_submit_form"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          initialValues={{
            name: particularVendorInformation.name,
          }}
        >


          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="id" label="Unique Identification Number">
                <Input disabled={true} size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>

            <Col span={12} >

              <Form.Item 
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  }
                ]}
              >
                <Input bordered={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  placeholder="Please enter url"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Enter email address",
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name="company_name" 
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                name="phone"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact number",
                  }
                ]}
              >
                <Input placeholder="981XXXXXXX" />
              </Form.Item>

            </Col>
            <Col span={12}>
              <Form.Item 
                name="country" 
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please select a country",
                  }
                ]}
              >
              <Select
                  showSearch
                  size="large"
                  placeholder="Please Select Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterData.basic.country.map(option => { return { value: option.code, label: option.name } })}
                >
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="region" label="Region">
                <Input />
              </Form.Item>

            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="complete_redirect"
                label="Complete Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quota_full_redirect"
                label="Quota Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="terminate_redirect"
                label="Terminate Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="security_redirect"
                label="Security Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="img"
                label="Image URL"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default EditVendorDrawer;