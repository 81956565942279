import {
    TRANSACTION_DATA_REQUESTED,
    TRANSACTION_DATA_SUCCESS,
    TRANSACTION_DATA_ERROR,
   
  } from "../actions/rewardActions/actionTypes";
  
  const INITIAL_STATE = {
    transactionData: [],
    fetchingTransactionData: false,
    transactionDataError: null,
   
  };
  
   const rewardReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
      case TRANSACTION_DATA_REQUESTED:
        return {
          ...state,
          fetchingTransactionData: true,
          transactionDataError: null,
        };
      case TRANSACTION_DATA_SUCCESS:
        return {
          ...state,
          transactionData: payload,
          fetchingTransactionData: false,
        };
      case TRANSACTION_DATA_ERROR:
        return {
          ...state,
          fetchingTransactionData: false,
          transactionDataError: payload,
        };
    
      default:
        return state;
    }
  };
  // const withdrawReducer = (state = INITIAL_STATE, action) => {
  //   const { type, payload } = action;

  //   switch (type) {
  //     case TRANSACTION_DATA_REQUESTED:
  //       return {
  //         ...state,
  //         fetchingTransactionData: true,
  //         transactionDataError: null,
  //       };
  //     case TRANSACTION_DATA_SUCCESS:
  //       return {
  //         ...state,
  //         transactionData: payload,
  //         fetchingTransactionData: false,
  //       };
  //     case TRANSACTION_DATA_ERROR:
  //       return {
  //         ...state,
  //         fetchingTransactionData: false,
  //         transactionDataError: payload,
  //       };
    
  //     default:
  //       return state;
  //   }
  // };
  export default 
    rewardReducer;
    
  
 
 