import {
  Button,
  Col,
  Input,
  Card,
  Drawer,
  Form,
  Statistic,
  Row,
  Space,
  Divider,
  Cascader,
  Select
} from "antd";
import { MinusCircleOutlined, PlusOutlined, UserOutlined, SendOutlined, NumberOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterData, notifyUserFilter } from "../../redux/actions/surveyActions"
import { getFiterUserCount } from "../../redux/actions/surveyActions";

const CampaignDrawer = ({
  isCampaignDrawerOpen,
  setIsCampaignDrawerOpen,
  particularCampaignInformation
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onSubmitSuccess = () => {
    setIsCampaignDrawerOpen(false);
  };

  useEffect(() => {
    dispatch(getFilterData());
  }, [dispatch]);

  const { filterData = {} } = useSelector(({ survey }) => survey);
  
  const onFinish = (data) => {
    const basic = {};
    const options = [];
    
    ["gender", "min_age", "max_age", "country"].map(name => {
      if (name in data){
        basic[name] = data[name]
      }
    });
    if ("profile_options" in data && data.profile_options != undefined){
      data.profile_options.map(element => {
        options.push(element.option[2])
      });
    }
   
    const payload = {filter: {basic: basic, profile:{options: options}}}
    console.log(payload);
    dispatch(getFiterUserCount(payload));
    
  };

  const { userCount = {}, fetching } = useSelector(({ survey }) => survey);
  console.log(userCount);
 
  const sendMail = (event) => {
    const basic = {};
    const options = [];

    const data = form.getFieldsValue(true);
    ["gender", "min_age", "max_age", "country"].map(name => {
      if (name in data){
        basic[name] = data[name]
      }
    });
    if ("profile_options" in data && data.profile_options != undefined){
      data.profile_options.map(element => {
        options.push(element.option[2])
      });
    }
    const survey_campaign_id = particularCampaignInformation.id;
   
    const notification = {email:{delivery_time:"3", duplicate:false, email_notification: true}, homepage:{push_notification:true}};
    
   
    const filter =  {basic: basic, profile:{options: options}}
    const value= {survey_campaign_id:survey_campaign_id, notification:notification,filter:filter};
    
    dispatch(notifyUserFilter(value));
    
  };
  const {noficationMessage ={} }= useSelector(({survey}) => survey);
  console.log(noficationMessage);
  
  
  

  console.log(filterData);
  const genderOptions = [];
  const countryOptions = [];
  const profileOptions = [];
  if (Object.keys(filterData).length > 0) {
    filterData.basic.gender.forEach(element => {
      genderOptions.push({
        value: element,
        label: element,
      });
    });

    filterData.basic.country.forEach(element => {
      countryOptions.push({
        value: element.code,
        label: element.name,
      });
    });

    Object.keys(filterData.profile_category).map(categoryName => {
      const categoryChild = {label:categoryName, value: categoryName, children:[]}
      const categoryValue = filterData.profile_category[categoryName]
      categoryValue.questions.map(question => {
        const questionChild = {label: question.title, value: question.id, children:[]}
        question.options.map(option => {
          questionChild.children.push({
            label: option.label,
            value: option.id
          })
        });
        categoryChild.children.push(questionChild)
      });
      profileOptions.push(categoryChild)
    });
  }

  const onClose = () => {
    setIsCampaignDrawerOpen(false);
  };
  return (
    <>

      <Drawer
        title="Filter users for Survey Campaign"
        width={720}
        closable={false}
        onClose={onClose}
        open={true}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            
            <Button form="filter_submit_form" key="findUserSubmit" htmlType="submit" type="primary">
              <NumberOutlined />
              Find Users
            </Button>
            <Button onClick={sendMail} key="sendMailSubmit" htmlType="submit" type="primary">
              <SendOutlined />
              Send
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          name="filter_submit_form"

        >
          <Row gutter={16} >

            <Col span={12}>
              <Card>
                <Statistic
                  title="User Count"
                

                  value={userCount}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<UserOutlined />}
                  suffix="Users"
                  
                />
              </Card>
            </Col>

            <Divider></Divider>

          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="gender"
                label="Gender"
              >
                <Select
                  placeholder="Select Gender"
                  options={genderOptions}
                  allowClear

                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Min Age" name="min_age">
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Max Age" name="max_age">
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Country" name="country">
                <Select
                  placeholder="Select Country"
                  options={countryOptions}
                >
                </Select>
              </Form.Item>
            </Col>
            


          </Row>
          
          <Form.List name="profile_options">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (

                  <Row gutter={16}>
                    
                    
                    <Col span={22}>
                    <Form.Item
                      {...restField}
                      name={[name, 'option']}
                      label={["Profile Question ", name]}

                    >
                      <Cascader options={profileOptions} placeholder="Please select" />
                    </Form.Item>
                    </Col>
                    <Col span={2}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                    
                  </Row>

                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Profile Question
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>

      </Drawer>
    </>
  );
};
export default CampaignDrawer;