
import surveyService from "../../../services/campaign/surveyService";
import{
    SURVEY_DATA_REQUESTED,
    SURVEY_DATA_SUCCESS,
    SURVEY_DATA_ERROR,
    SURVEY_DATA_SUBMIT_INITIATED,
    SURVEY_DATA_SUBMIT_SUCCESS,
    SURVEY_DATA_SUBMIT_ERROR,
    SURVEY_INVOIVE_CLIENT_DATA_REQUESTED,
    SURVEY_INVOIVE_CLIENT_DATA_SUCCESS,
    SURVEY_INVOIVE_CLIENT_DATA_ERROR,
    SURVEY_INVOIVE_VENDOR_DATA_REQUESTED,
    SURVEY_INVOIVE_VENDOR_DATA_SUCCESS,
    SURVEY_INVOIVE_VENDOR_DATA_ERROR,
    FILTER_DATA_REQUESTED,
    FILTER_DATA_SUCCESS,
    FILTER_DATA_ERROR,
    USER_COUNT_SUBMIT_INITIATED,
    USER_COUNT_SUBMIT_SUCCESS,
    USER_COUNT_SUBMIT_ERROR,
    NOTIFY_USER_SUBMIT_INITIATED,
    NOTIFY_USER_SUBMIT_SUCCESS,
    NOTIFY_USER_SUBMIT_ERROR

} from "./actionTypes";
import { notification } from "antd";

export const getSurveyList = (filterdata) => async (dispatch, getState) => {
    dispatch({
      type: SURVEY_DATA_REQUESTED,
    });
  
    try {
      const response = await surveyService.listSurveys(filterdata);
      if (response?.data?.error) {
        dispatch({
          type: SURVEY_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: response.data.message})
      } else {
        dispatch({
          type: SURVEY_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SURVEY_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: error?.response?.data?.message})
    }
  };
  export const saveSurveyData =
  (surveyData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: SURVEY_DATA_SUBMIT_INITIATED,
    });

    try {
      const response = await surveyService.saveSurvey(surveyData);
      
      if (response?.data?.error) {
        dispatch({
          type: SURVEY_DATA_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: SURVEY_DATA_SUBMIT_SUCCESS,
          payload: surveyData,
        });
        notification.success({
          message: "SURVEY's details Submitted Successfully",
        });
        onSubmitSuccess();
      }
    } catch (error) {
      dispatch({
        type: SURVEY_DATA_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };

  export const getFilterData = () => async (dispatch, getState) => {
    dispatch({
      type: FILTER_DATA_REQUESTED,
    });
  
    try {
      const response = await surveyService.getFilterData();
      if (response?.data?.error) {
        dispatch({
          type: FILTER_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: FILTER_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: FILTER_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };

  export const invoiceClient= (client_id) => async (dispatch, getState) => {
    dispatch({
      type: SURVEY_INVOIVE_CLIENT_DATA_REQUESTED,
    });
  
    try {
      const response = await surveyService.getInvoiveClient(client_id);
      if (response?.data?.error) {
        dispatch({
          type: SURVEY_INVOIVE_CLIENT_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: SURVEY_INVOIVE_CLIENT_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SURVEY_INVOIVE_CLIENT_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };
  export const invoiceVendor= (vendor_id) => async (dispatch, getState) => {
    dispatch({
      type: SURVEY_INVOIVE_VENDOR_DATA_REQUESTED,
    });
  
    try {
      const response = await surveyService.getInvoiveVendor(vendor_id);
      if (response?.data?.error) {
        dispatch({
          type: SURVEY_INVOIVE_VENDOR_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: SURVEY_INVOIVE_VENDOR_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SURVEY_INVOIVE_VENDOR_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };

  export const getFiterUserCount=
  (filterData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: USER_COUNT_SUBMIT_INITIATED,
    });

    try {
      const response = await surveyService.getUserCount(filterData);
      
      if (response?.data?.error) {
        dispatch({
          type: USER_COUNT_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: USER_COUNT_SUBMIT_SUCCESS,
          payload:response.data.count,
        });
        
        
        onSubmitSuccess();
      }
    } catch (error) {
      dispatch({
        type: USER_COUNT_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };

  export const notifyUserFilter=
  (filterData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: NOTIFY_USER_SUBMIT_INITIATED,
    });

    try {
      const response = await surveyService.notifyUser(filterData);
      
      if (response?.data?.error) {
        dispatch({
          type: NOTIFY_USER_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: NOTIFY_USER_SUBMIT_SUCCESS,
          payload:response.data.message,
        });
        notification.success({
          message: response.data.message,
        });
        
        onSubmitSuccess();
      }
    } catch (error) {
      dispatch({
        type: NOTIFY_USER_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };

 