import { Row, Col, Card, Table, Avatar, Typography, Button, Popover } from "antd";
import { PlusOutlined, EditTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getClientList } from "../redux/actions/clientActions/index";
import EditClientDrawer from "../components/EditClientDrawer/index.js";
import { Input } from 'antd';

const { Title } = Typography;
const { Search } = Input;

function Client() {
  // Control drawer
  const [isEditableDrawerOpen, setIsEditableDrawerOpen] = useState(false);
  const [particularClientInformation, setParticularClientInformation] =
    useState({});

  // Fetch data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientList({}));
  }, [dispatch]);

  const { clients = [], fetching } = useSelector(({ client }) => client);
  const { items = [], pagination = {} } = clients
  const { total = 20, page = 1, page_size = 10 } = pagination
  let tableParams = { pagination: { current: page, pageSize: page_size, total: total } }



  const onRowClick = (clientInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularClientInformation(clientInformation);
  };

  const onSearch = (value) => {
    const filterdata = { "filters": { "name__icontains": value } }
    dispatch(getClientList(filterdata));
  }

  const reloadClients = () => {
    dispatch(getClientList({}));
  }

  const handleTablePageChange = (pagination, filters, sorter) => {
    tableParams.pagination.current = pagination.current
    dispatch(getClientList({ "filters": {}, "page": pagination.current, "order_by":["-created_at"], }));
  };
  
  const columns = [
    
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => (
        <>
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="round"
            size={30}
            src={record.img}  
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>{data} <Popover content="Edit Details"><EditTwoTone onClick={() => onRowClick(record)} /> </Popover></Title>
            <p>{record.company_name}</p>
          </div>
        </Avatar.Group>
        
        </>
      ),
    },
    {
      title: "Contact Information",
      dataIndex: "phone",
      key: "phone",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.email}</div>
          <div>{record.website}</div>
        </>
      ),
    },
    {
      title: "Region",
      dataIndex: "country",
      key: "country",
      // align: "center",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.city}</div>
        </>
      ),
    },
    {
      title: "Added on",
      dataIndex: "created_at",
      key: "created_at",
      // width: "110px",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.updated_at}</div>
        </>
      ),
    }
  ];

  return (
    <>
      <div className="tabled">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title="Clients Details"
          extra={
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ color: 'white', zIndex: 10 }}
              onClick={() => onRowClick({})}
            >
              Add Client
            </Button>
          }
        >
          <Row >
            <Col span={8}>
              <div style={{ marginLeft: '5%' }}>
                <Search placeholder="Search By Name" onSearch={onSearch} enterButton />
              </div>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={items}
              pagination={tableParams.pagination}
              loading={fetching}
              rowKey={(record) => record.id}
              onChange={handleTablePageChange}
              className="ant-border-space"
            />
          </div>
        </Card>
      </div>
      {isEditableDrawerOpen && (
        <EditClientDrawer
          isEditableDrawerOpen={isEditableDrawerOpen}
          setIsEditableDrawerOpen={setIsEditableDrawerOpen}
          particularClientInformation={particularClientInformation}
          setParticularClientInformation={setParticularClientInformation}
          reloadClients={reloadClients}
        />
      )}
    </>
  );
}

export default Client;
