import {
  CLIENT_DATA_ERROR,
  CLIENT_DATA_REQUESTED,
  CLIENT_DATA_SUCCESS,
  CLIENT_DATA_SUBMIT_INITIATED,
  CLIENT_DATA_SUBMIT_SUCCESS,
  CLIENT_DATA_SUBMIT_ERROR
} from "../actions/clientActions/actionTypes";

const INITIAL_STATE = {
  clients: [],
  fetching: false,
  error: null,
  submiting: false,
  submitingError: null,
};

const clientReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_DATA_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case CLIENT_DATA_SUCCESS:
      return {
        ...state,
        clients: payload,
        fetching: false,
      };
    case CLIENT_DATA_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case CLIENT_DATA_SUBMIT_INITIATED:
      return {
        ...state,
        submiting: true,
      };
      
      case CLIENT_DATA_SUBMIT_SUCCESS:
        return {
          ...state,
          submiting: false,
        };
    case CLIENT_DATA_SUBMIT_ERROR:
      return {
        ...state,
        submiting: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
