import axios from "axios";
import { canonicalizeResponse, authHeader } from "../common";

const getSurvey = (survey_id) => {
  return axios
    .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/survey/" +  survey_id + "/", {
        
      headers: authHeader(),
    })
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const listSurveys = (filterdata) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/surveys",
      filterdata,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const saveSurvey = (surveyData) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/survey/",
      surveyData,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};
const listUserSurveys = (user_id) => {
    return axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/surveys" + user_id + "/",
        user_id,
        { headers: authHeader() }
      )
      .then((response) => {
        return canonicalizeResponse(response);
      });
  };
  const getInvoiveClient = (client_id) => {
    return axios
      .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/invoice/" +  client_id + "/client", {
        headers: authHeader(),
      })
      .then((response) => {
        return canonicalizeResponse(response);
      });
  };
  const getInvoiveVendor = (vendor_id) => {
    return axios
      .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/invoice/" +  vendor_id + "/vendor", {
        headers: authHeader(),
      })
      .then((response) => {
        return canonicalizeResponse(response);
      });
  };

  const getFilterData = () => {
    return axios
      .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/filters/", {
        headers: authHeader(),
      })
      .then((response) => {
        localStorage.setItem("filterData", JSON.stringify(response.data));
        return canonicalizeResponse(response);
      });
  };

  const getUserCount = (filterData) => {
    return axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/filters/apply/",
        filterData,
        { headers: authHeader() }
      )
      .then((response) => {
        return canonicalizeResponse(response);
      });
  };

  const notifyUser= (filterData) => {
    return axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/notify",
        filterData,
        { headers: authHeader() }
      )
      .then((response) => {
        return canonicalizeResponse(response);
      });
  };

export default {
  getSurvey,
  listSurveys,
  saveSurvey,
  listUserSurveys,
  getInvoiveClient,
  getInvoiveVendor,
  getFilterData,
  getUserCount,
  notifyUser
};