import clientService from "../../../services/campaign/clientService";
import {
    CLIENT_DATA_SUCCESS,
    CLIENT_DATA_ERROR,
    CLIENT_DATA_REQUESTED,
    CLIENT_DATA_SUBMIT_INITIATED,
    CLIENT_DATA_SUBMIT_SUCCESS,
    CLIENT_DATA_SUBMIT_ERROR
} from "./actionTypes";
import { notification } from "antd";

export const getClientList = (filterData, isCached=false) => async (dispatch, getState) => {
    dispatch({
      type: CLIENT_DATA_REQUESTED,
    });
  
    try {
      const response = await clientService.listClients(filterData);
      if (response?.data?.error) {
        dispatch({
          type: CLIENT_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: CLIENT_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CLIENT_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };

  export const saveClientData =
  (clientData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: CLIENT_DATA_SUBMIT_INITIATED,
    });

    try {
      const response = await clientService.saveClient(clientData);
      
      if (response?.data?.error) {
        dispatch({
          type: CLIENT_DATA_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: CLIENT_DATA_SUBMIT_SUCCESS,
          payload: clientData,
        });
        notification.success({
          message: "Client's details Submitted Successfully",
        });
        onSubmitSuccess();
      }
    } catch (error) {
      dispatch({
        type: CLIENT_DATA_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };