import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Lottie from "react-lottie";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import carLoader from "./components/LottieAnimations/carLoader.json";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: carLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

ReactDOM.render(
  <Suspense
    fallback={<Lottie options={loaderOptions} height={100} width={100} />}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
