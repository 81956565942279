import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Typography,
  Spin,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { GoogleLogin } from "@react-oauth/google";
import { Login } from "../redux/actions/authActions/auth";
import signinbg from "../assets/images/img-signin.jpg";
import { useDispatch, useSelector } from "react-redux";
const { Title } = Typography;
const { Footer, Content } = Layout;


const Home = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state) => state.auth);
  const [isLoginInProgres, setIsLoginInProgres] = useState(false);
  const history = useHistory();
  if (isAdmin) {
    history.push("/survey");
  }

  useEffect(() => {}, [dispatch]);

  const onGoogleLoginSuccess = (res) => {
    setIsLoginInProgres(true)
    console.log("success:", res);
    dispatch(Login("google", res))
    .then(() => {})
    .catch(() => {
      console.log("failed:", res);
      history.push("/");
    });
  };

  const onGoogleLoginFailure = (err) => {
    console.log("failed:", err);
  };


  return (
    <>
      <Layout className="layout-default layout-signin">

        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Campaign Manager</Title>
              <Title className="font-regular text-muted" level={5}>
                Manage Survey campaigns 
              </Title>
              <GoogleLogin
                onSuccess={onGoogleLoginSuccess}
                onError={onGoogleLoginFailure}
                text="continue_with"
                size="large"
                useOneTap
              />
              {isLoginInProgres && 
              <Spin 
                indicator={antIcon}
                tip ="Loggin In"
              />}
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2022  <a href="#pablo">Continumm insights</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
}
export default Home;
