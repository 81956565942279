import axios from "axios";
import { canonicalizeResponse, authHeader } from "../common";

const getClient = (client_id) => {
  return axios
    .get(process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/client/" +  client_id + "/", {
      headers: authHeader(),
    })
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const listClients = (filterData) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/clients",
      filterData,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};

const saveClient = (clientData) => {
  return axios
    .post(
      process.env.REACT_APP_BASE_API_URL + "/v1/api/campaign/client",
      clientData,
      { headers: authHeader() }
    )
    .then((response) => {
      return canonicalizeResponse(response);
    });
};


export default {
  getClient,
  listClients,
  saveClient
};
