import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../actions/authActions/types"
  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState = user ? { isLoggedIn: true, user: user, isAdmin: user.is_admin} : { isLoggedIn: false, user: null, isAdmin: false };
  
  const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
       case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          isAdmin: payload.user.is_admin,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      default:
        return state;
    }
  }


  export default authReducer;
  