
import {
    Button,
    Col,
    Input,
    
    Drawer,
    Form,
    
    Row,
    Descriptions,
    
    Space,
    
  } from "antd";
import React from "react";

import { useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { invoiceVendor } from "../../redux/actions/surveyActions";

const InvoiceVendorDrawer = ({
    isInvoiceVendorDrawerOpen, 
    setIsInvoiceVendorDrawerOpen,
    vendor_id
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    useEffect((vendor_id) => {
        dispatch(invoiceVendor(1));
      }, [dispatch]);

      const { vendorInvoice = {}, fetching } = useSelector(({ survey }) => survey);

    const onClose = () => {
        setIsInvoiceVendorDrawerOpen(false);
      };

    return(
        <Drawer
        title="Vendor Invoice"
        width={720}
        closable={false}
        onClose={onClose}
        open={isInvoiceVendorDrawerOpen}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
           
              
              <Button onClick={onClose}>Close</Button>
           
          }
        >
          
          <Descriptions
      title="Vendor Invoice Details"
      bordered
      column={{
        xxl: 1,
        xl: 1,
        lg: 1,
        md: 1,
        sm: 1,
        xs: 1,
      }}
    >
      <Descriptions.Item label="Captured Count">{vendorInvoice.captured_count}</Descriptions.Item>
      <Descriptions.Item label="CPI">{vendorInvoice.cpi}</Descriptions.Item>
      <Descriptions.Item label="Count">{vendorInvoice.count}</Descriptions.Item>
      <Descriptions.Item label="Currency">{vendorInvoice.currency}</Descriptions.Item>
      <Descriptions.Item label="Tax">$20.00</Descriptions.Item>
      <Descriptions.Item label="Total Amount">{vendorInvoice.total}</Descriptions.Item>
      
    </Descriptions>

        </Drawer>
    )
};
export default InvoiceVendorDrawer;