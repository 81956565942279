import vendorService from "../../../services/campaign/vendorService";
import {
    VENDOR_DATA_SUCCESS,
    VENDOR_DATA_ERROR,
    VENDOR_DATA_REQUESTED,
    VENDOR_DATA_SUBMIT_INITIATED,
    VENDOR_DATA_SUBMIT_SUCCESS,
    VENDOR_DATA_SUBMIT_ERROR
} from "./actionTypes";
import { notification } from "antd";

export const getVendorList = (body) => async (dispatch, getState) => {
    dispatch({
      type: VENDOR_DATA_REQUESTED,
    });
  
    try {
      const response = await vendorService.listVendors(body);
      if (response?.data?.error) {
        dispatch({
          type: VENDOR_DATA_ERROR,
          payload: response.data.message,
        });
        notification.error({message: "Unable to Fetch data"})
      } else {
        dispatch({
          type: VENDOR_DATA_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: VENDOR_DATA_ERROR,
        payload: error?.response?.data?.message,
      });
      notification.error({message: "Unable to Fetch data"})
    }
  };

  export const saveVendorData =
  (vendorData, onSubmitSuccess) => async (dispatch, getState) => {
    dispatch({
      type: VENDOR_DATA_SUBMIT_INITIATED,
    });

    try {
      const response = await vendorService.saveVendor(vendorData);
      
      if (response?.data?.error) {
        dispatch({
          type: VENDOR_DATA_SUBMIT_ERROR,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: VENDOR_DATA_SUBMIT_SUCCESS,
          payload: vendorData,
        });
        notification.success({
          message: "Vendor's details Submitted Successfully",
        });
        onSubmitSuccess();
      }
    } catch (error) {
      dispatch({
        type: VENDOR_DATA_SUBMIT_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };