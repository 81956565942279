export const SURVEY_DATA_REQUESTED = "SURVEY_DATA_REQUESTED";
export const SURVEY_DATA_SUCCESS = "SURVEY_DATA_SUCCESS";
export const SURVEY_DATA_ERROR = "SURVEY_DATA_ERROR";

export const SURVEY_DATA_SUBMIT_INITIATED = "SURVEY_DATA_SUBMIT_INITIATED";
export const SURVEY_DATA_SUBMIT_SUCCESS = "SURVEY_DATA_SUBMIT_SUCCESS";
export const SURVEY_DATA_SUBMIT_ERROR = "SURVEY_DATA_SUBMIT_ERROR";

export const SURVEY_INVOIVE_CLIENT_DATA_REQUESTED = "SURVEY_INVOIVE_CLIENT_DATA_REQUESTED";
export const SURVEY_INVOIVE_CLIENT_DATA_SUCCESS = "SURVEY_INVOIVE_CLIENT_DATA_SUCCESS";
export const SURVEY_INVOIVE_CLIENT_DATA_ERROR = "SURVEY_INVOIVE_CLIENT_DATA_ERROR";

export const SURVEY_INVOIVE_VENDOR_DATA_REQUESTED = "SURVEY_INVOIVE_VENDOR_DATA_REQUESTED";
export const SURVEY_INVOIVE_VENDOR_DATA_SUCCESS = "SURVEY_INVOIVE_VENDOR_DATA_SUCCESS";
export const SURVEY_INVOIVE_VENDOR_DATA_ERROR = "SURVEY_INVOIVE_VENDOR_DATA_ERROR";

export const FILTER_DATA_REQUESTED = "FILTER_DATA_REQUESTED";
export const FILTER_DATA_SUCCESS = "FILTER_DATA_SUCCESS";
export const FILTER_DATA_ERROR = "FILTER_DATA_ERROR";

export const USER_COUNT_SUBMIT_INITIATED = "USER_COUNT_SUBMIT_INITIATED";
export const USER_COUNT_SUBMIT_SUCCESS = "USER_COUNT_SUBMIT_SUCCESS";
export const USER_COUNT_SUBMIT_ERROR = "USER_COUNT_SUBMIT_ERROR";

export const NOTIFY_USER_SUBMIT_INITIATED = "NOTIFY_USER_SUBMIT_INITIATED";
export const NOTIFY_USER_SUBMIT_SUCCESS = "NOTIFY_USER_SUBMIT_SUCCESS";
export const NOTIFY_USER_SUBMIT_ERROR = "NOTIFY_USER_SUBMIT_ERROR";

