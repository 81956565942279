import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Descriptions,
  Spin,
  Collapse,
  Timeline,
  Tag,
  Switch,
} from "antd";
import { CopyOutlined, SaveOutlined, CloseSquareOutlined,CheckCircleOutlined} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveSurveyData } from "../../redux/actions/surveyActions";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const EditSurveyDrawer = ({
  setIsEditableDrawerOpen,
  particularSurveyInformation,
  reloadSurveys
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    setIsLoading(false)
    reloadSurveys();
  }
  console.log(particularSurveyInformation)
  const metadata = particularSurveyInformation.metadata

  // console.log("checkvalue", "is_agent_check_enabled" in metadata && metadata.is_agent_check_enabled)
  let isAgentCheckEnabled = metadata != undefined && "is_agent_check_enabled" in metadata && metadata.is_agent_check_enabled
  let isInternalPanel = metadata != undefined && "is_agent_check_enabled" in metadata && metadata.is_internal_panel

  const [isInternalPanelChecked, setIsInternalPanelChecked] = useState(isInternalPanel)
  const [isLoading, setIsLoading] = useState(false);
  const { filterData = {} } = useSelector(({ survey }) => survey);


  const canonicalizeValues = (value) => {
    setIsLoading(true)
    console.log(value);
    value.start_date = value.dateRange[0].format("YYYY-MM-DD")
    value.end_date = value.dateRange[1].format("YYYY-MM-DD")
    value.is_agent_check_enabled = isAgentCheckEnabled
    value.is_internal_panel = isInternalPanelChecked
    value.other = {}
    value.other_redirects = {}
    value.profile_condition = {}
    return value
  }

  const onFinish = (_) => {
    let value = form.getFieldsValue(true);
    value = canonicalizeValues(value)
    dispatch(saveSurveyData(value, onSaveSuccess));
  };

  const onSaveCopy = (event) => {
    let value = form.getFieldsValue(true);
    value = canonicalizeValues(value)
    value.is_save_copy = true;
    dispatch(saveSurveyData(value, onSaveSuccess));
  }

  const onVendorSelect = (value, option) => {
    const vendor = filterData.vendors.filter((option) => option.id === value)[0];
    const values = {
      complete_redirect: vendor.complete_redirect,
      quota_full_redirect: vendor.quota_full_redirect,
      terminate_redirect: vendor.terminate_redirect,
      security_redirect: vendor.security_redirect
    }
    form.setFieldsValue(values);
  }
  useEffect(() => {
    if (Object.keys(particularSurveyInformation).length) {
      particularSurveyInformation.dateRange = [moment(particularSurveyInformation.start_date, "DD MMM YY, h:mm:ss"),
      moment(particularSurveyInformation.end_date, "DD MMM YY, h:mm:ss")]
      form.setFieldsValue(particularSurveyInformation);
    }
  }, [particularSurveyInformation, form]);
  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  const isAdd = JSON.stringify(particularSurveyInformation) === '{}';
  let size = Math.min(window.innerWidth, Math.max(window.innerWidth / 1.8, 720))

  let client_cpi_history = []
  if ("metadata" in particularSurveyInformation && "client_cpi_history" in particularSurveyInformation.metadata) {
    client_cpi_history = particularSurveyInformation.metadata.client_cpi_history
  }

  const title = isAdd ? "Add New Survey Campaign" : "Edit Survey Campaign"

  const onChangeCampaignPanel = (event) => {
    setIsInternalPanelChecked(event)
  }

  const onChangeAgentCheck = (event) => {

    isAgentCheckEnabled = event
    console.log("onChangeAgentCheck", isAgentCheckEnabled)
  }

  return (
    <>
      <Drawer
        title={<>

          <h2>{title}</h2>
          <p>Internal Panel:
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              style={{ marginLeft: "10px" }}
              defaultChecked={isInternalPanelChecked}
              onChange={onChangeCampaignPanel}

            />
          </p>
          <p>Enable Agent check:
            <Switch
              defaultChecked={isAgentCheckEnabled}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              style={{ marginLeft: "10px" }}
              onChange={onChangeAgentCheck}
            />
          </p>
         
        </>}
        width={size}
        closable={false}
        onClose={onClose}
        open={true}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onFinish} key="save" htmlType="submit" type="primary" disabled={isLoading}>
              <SaveOutlined />
              Save
            </Button>
            {/* {!isAdd &&
              <Button  key="approve" htmlType="submit" type="primary" disabled={isLoading}>
              <CheckCircleOutlined />
                Approve
              </Button>
            } */}
            {!isAdd &&
              <Button onClick={onSaveCopy} key="saveCopy" htmlType="submit" type="primary" disabled={isLoading}>
                <CopyOutlined />
                Save as New Copy
              </Button>
            }
            <Button onClick={onClose}><CloseSquareOutlined /> Cancel</Button>
          </Space>
        }
      >
        {isLoading ?
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}> <Spin size="large" /> Saving ..  </Space>
          :
          <Form
            id="survey_submit_form"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              name: particularSurveyInformation.name,
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="id" label="Unique ID (Read only)">
                  <Input readOnly={true} placeholder="Generated after save" bordered={true} status="warning" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ci_code"
                  label="CI Code"
                  rules={[
                    {
                      required: true,
                      message: "Please provide CI code CPI",
                    },
                  ]}
                >
                  <Input placeholder="Continuum Insight code"></Input>
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="Please Enter Survey Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="wave" label="Wave">
                  <Input placeholder="Wave (optional)" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Category Type"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the type",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Please Select Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Object.keys(filterData.profile_category).map(option => { return { value: option, label: option } })}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the Country",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    size="large"
                    placeholder="Please Select Country (seach code)"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={filterData.basic.country.map(option => { return { value: option.code, label: option.name + " (" + option.code + ")" } })}
                  >
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Current status of survey",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Please select an status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={filterData.status.campaign.map(option => { return { value: option, label: option } })}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dateRange"
                  label="Start and End date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start and end dates",
                    }
                  ]}
                >
                  <RangePicker
                    size="large"
                    format={"DD MMM YY"}
                    style={{ width: "100%", }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="min_loi" label="Minimum LOI">
                  <Input type="number" placeholder="Minimum loi in minutes"></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="max_loi"
                  label="Maximum LOI"
                >
                  <Input type="number" placeholder="Maximum loi in minutes"></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="expected_ir" label="Expected IR" placeholder="3">
                  <Input type="number" placeholder="Expected IR"></Input>
                </Form.Item>
              </Col>

            </Row>

            {isInternalPanelChecked && <>
              <Divider>Respondent Settings Information</Divider>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="respondent_currency" label="Respondent currency">
                    <Select
                      size="large"
                      showSearch
                      placeholder="Please Select Currency"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                    >
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="respondent_cpi"
                    label="Respondent CPI"
                  >
                    <Input type="number"></Input>
                  </Form.Item>
                </Col>

              </Row>

              <Divider>User Interface Information</Divider>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="ui_title" label="UI Title">
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ui_icon"
                    label="UI Icon URL"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="ui_description"
                    label="Description"
                  >
                    <Input style={{ width: "100%", }} />
                  </Form.Item>
                </Col>

              </Row>
            </>
            }

            <Divider>Client Information</Divider>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="client_id"
                  label="Client"
                  rules={[
                    {
                      required: true,
                      message: "Please select a client",
                    }
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Please select an client"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={filterData.clients.map(option => { return { value: option.id, label: option.name } })}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="client_code"
                  label="Client Code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter client code",
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="client_live_url"
                  label="Client Live URL"
                  rules={[
                    {
                      required: true,
                      message: "Please enter link here",
                    },
                    {
                      type: "url",
                      message: "This field must be a valid url."
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}

                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="client_test_url"
                  label="Client Test URL"
                  rules={[
                    {
                      type: "url",
                      message: "This field must be a valid url."
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}

                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="client_completed_count" label="Client Completed Count">
                  <Input type="number" defaultValue="0" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="client_partial_count" label="Client Partial Count">
                  <Input type="number" defaultValue="0" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="client_cpi" label="Client CPI"
                  rules={[
                    {
                      required: true,
                      message: "Please mention Client CPI ",
                    },
                  ]}>
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="client_currency"
                  label="Client Currency"
                  rules={[
                    {
                      required: true,
                      message: "Client Currency is required",

                    },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Please Select Currency"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                  >
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {!isAdd &&
              <Row gutter={16}>
                <Col span={24}>
                  <Collapse >
                    <Collapse.Panel header="Client CPI History" key="1">
                      <Timeline reverse={true}>
                        {
                          client_cpi_history.map((item) => {
                            return <Timeline.Item key={item.datetime}><Tag><b>{item.cpi}</b></Tag> by <Tag>{item.user}</Tag> @ <Tag>{item.datetime} </Tag></Timeline.Item>
                          })
                        }
                      </Timeline>

                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            }
            {!isInternalPanelChecked &&
              <>
                <Divider>Vendor Information</Divider>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="vendor_id" label="Vendor ID"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Please mention Vendor ID",
                        },
                      ]}>
                      <Select
                        size="large"
                        showSearch
                        placeholder="Please select vendor"
                        optionFilterProp="children"
                        onSelect={onVendorSelect}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={filterData.vendors.map(option => { return { value: option.id, label: option.name } })}
                      >
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="vendor_cpi"
                      label="Vendor CPI"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Vendor CPI is required",

                        },
                      ]}
                    >
                      <Input type="number" defaultValue="0" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="vendor_daily_limit" label="Vendor Daily Limit">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="vendor_currency"
                      label="Vendor Currency"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Vendor Currency is required",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Please Select Currency"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                      >
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="vendor_project_limit" label="Vendor Project Limit">
                      <Input type="number" defaultValue="0" />
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="complete_redirect"
                      label="Complete Redirect Link"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Please enter link here",
                        },
                        {
                          type: "url",
                          message: "This field must be a valid url."
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="quota_full_redirect"
                      label="Quota Redirect Link"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Please enter link here",
                        },
                        {
                          type: "url",
                          message: "This field must be a valid url."
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="terminate_redirect"
                      label="Terminate Redirect Link"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Please enter link here",
                        },
                        {
                          type: "url",
                          message: "This field must be a valid url."
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="security_redirect"
                      label="Security Redirect Link"
                      rules={[
                        {
                          required: !isInternalPanelChecked,
                          message: "Please enter link here",
                        },
                        {
                          type: "url",
                          message: "This field must be a valid url."
                        }
                      ]}
                    >
                      <Input
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {isAdd && 
                  <>
                    <Divider>Links Information</Divider>
                    <Descriptions bordered>
                      <Descriptions.Item label="Start URL (for vendor)" span={4}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/start?camp=" + particularSurveyInformation.id + "&resp=[XXX]"}</Descriptions.Item>
                      <Descriptions.Item label="Complete URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/capture/completed"}</Descriptions.Item>
                      <Descriptions.Item label="Security URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/capture/security"}</Descriptions.Item>
                      <Descriptions.Item label="Quota Full URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/capture/quota"}</Descriptions.Item>
                      <Descriptions.Item label="Terminate URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/capture/terminated"}</Descriptions.Item>
                    </Descriptions>
                  </>
                }
              </>
            }
          </Form>
        }
      </Drawer>

    </>
  );
};
export default EditSurveyDrawer;